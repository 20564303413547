export const formatterTreeFolder = (preData) => {
  return preData.map((item) => {
    if (item.file_type === 'folder') {
      return {
        title: item.name,
        isExpanded: false,
        data: {
          ...item,
          isGetData: false,
          source_id: item.id
        },
        children: []
      }
    }
    return {
      title: item.name,
      isLeaf: true,
      data: {
        ...item
      }
    }
  })
}

export const findById = (nodes, id) => {
  let result = {}
  for (const child of nodes) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}

function recurseFind(node, id) {
  if (node.data.file_type !== 'folder') {
    return {}
  }

  if (node.data.id === id) {
    return node
  }

  let result = {}
  for (const child of node.children) {
    if (Object.keys(result).length) break
    const nodeTemp = recurseFind(child, id)
    result = Object.keys(nodeTemp).length ? nodeTemp : result
  }
  return result
}

export function traverseModels(cb, nodeModels) {
  let i = nodeModels.length
  while (i--) {
    const nodeModel = nodeModels[i]
    if (nodeModel.children) traverseModels(cb, nodeModel.children)
    cb(nodeModel, nodeModels, i)
  }
  return nodeModels
}
