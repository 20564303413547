import SmartAssetsService from '@/services/smart-assets.service'
import FolderService from '@/services/folder.service'
import Vue from 'vue'

export default {
  // LIBARY PAGE
  getListSmartAsset({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    commit('SET_ITEMS', null)
    return SmartAssetsService.getListSmartAsset(
      payload.page,
      payload.limit,
      payload.search,
      'library',
      null,
      payload.type,
      payload.orientation
    )
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_EMPTY', false)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getLoopsByAssetID({ state, commit, rootState }, payload) {
    commit('SET_LOOP_LOADING', true)
    commit('SET_LOOP_ITEMS', null)
    return SmartAssetsService.getLoopsByAssetID(
      payload.assetID,
      payload.page,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_LOOP_ITEMS', response.data)
        commit('SET_LOOP_ERROR', '')
      })
      .catch((error) => {
        commit('SET_LOOP_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOOP_LOADING', false))
  },

  getCampaignsByAssetID({ state, commit, rootState }, payload) {
    commit('SET_CAMPAIGN_LOADING', true)
    commit('SET_CAMPAIGN_ITEMS', null)
    return SmartAssetsService.getCampaignsByAssetID(
      payload.assetID,
      payload.page,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_CAMPAIGN_ITEMS', response.data)
        commit('SET_CAMPAIGN_ERROR', '')
      })
      .catch((error) => {
        commit('SET_CAMPAIGN_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_CAMPAIGN_LOADING', false))
  },

  getPlayerByAssetID({ state, commit, rootState }, payload) {
    commit('SET_PLAYER_LOADING', true)
    commit('SET_PLAYER_ITEMS', null)
    return SmartAssetsService.getPlayerByAssetID(
      payload.assetID,
      payload.page,
      payload.limit,
      payload.search
    )
      .then((response) => {
        commit('SET_PLAYER_ITEMS', response.data)
        commit('SET_PLAYER_ERROR', '')
      })
      .catch((error) => {
        commit('SET_PLAYER_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_PLAYER_LOADING', false))
  },

  getListSmartAssetInFolder({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return FolderService.getAssetInFolder(
      payload.id,
      payload.page,
      payload.limit,
      'library',
      null,
      payload.orientation,
      payload.search,
      payload.type
    )
      .then((response) => {
        commit('SET_ITEMS_IN_FOlDER', response.data)
        commit('SET_SELECTED_FOLDER', payload.id)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  moveContentFolderToItems({ sate, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return FolderService.getAssetInFolder(payload.id, payload.page, payload.limit, 'library')
      .then((response) => {
        commit('SET_ITEMS_IN_FOlDER_AFTER_REMOVE', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getListFolder({ state, commit, rootState }, payload) {
    return FolderService.getListFolders('library')
      .then((response) => {
        commit('SET_TREE_FOLDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  getTreeFolderChildById({ state, commit, rootState }, folderId) {
    if (!folderId) return
    let ignoreSelected = false
    if (typeof folderId === 'object') {
      ignoreSelected = folderId.ignoreSelected
      folderId = folderId.id
    }
    return FolderService.getListChildrenFolders(folderId, 'library')
      .then((response) => {
        commit('SET_TREE_FOLDER_ID', {
          id: folderId,
          items: response.data,
          ignoreSelected: ignoreSelected
        })
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  addNewLibraryItem({ state, commit, rootState }, payload) {
    commit('SET_NEW_LIBRARY_ITEMS', payload)
  },

  updateAssetIds({ state, commit, rootState }, payload) {
    commit('SET_EDITABLE_ASSET_IDS', payload)
  },

  resetAssetIds({ state, commit, rootState }, payload) {
    commit('RESET_EDITABLE_ASSET_IDS', payload)
  }
  // ===========================================================================
}
