import FolderService from '@/services/folder.service'
import LoopAssetService from '@/services/loop-assets.service'
import Vue from 'vue'

export default {
  // LOOP PAGE
  getListLoopAsset({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    commit('SET_ITEMS', null)
    return LoopAssetService.getListLoopAssets(
      payload.page,
      payload.limit,
      payload.search,
      payload.orientation
    )
      .then((response) => {
        commit('SET_ITEMS', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getListFolder({ state, commit, rootState }, payload) {
    return FolderService.getListFolders('loops')
      .then((response) => {
        commit('SET_TREE_FOLDER', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },

  getListSmartAssetInFolder({ state, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return FolderService.getAssetInFolder(
      payload.id,
      payload.page,
      payload.limit,
      'loop_edit',
      null,
      payload.orientation,
      payload.search
    )
      .then((response) => {
        commit('SET_ITEMS_IN_FOlDER', response.data)
        commit('SET_SELECTED_FOLDER', payload.id)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },

  getTreeFolderChildById({ state, commit, rootState }, folderId) {
    if (!folderId) return
    let ignoreSelected = false
    if (typeof folderId === 'object') {
      ignoreSelected = folderId.ignoreSelected
      folderId = folderId.id
    }
    return FolderService.getListChildrenFolders(folderId, 'loops')
      .then((response) => {
        commit('SET_TREE_FOLDER_ID', {
          id: folderId,
          items: response.data,
          ignoreSelected: ignoreSelected
        })
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
  },
  moveContentFolderToItems({ sate, commit, rootState }, payload) {
    commit('SET_LOADING', true)
    return FolderService.getAssetInFolder(payload.id, payload.page, payload.limit)
      .then((response) => {
        commit('SET_ITEMS_IN_FOlDER_AFTER_REMOVE', response.data)
        commit('SET_ERROR', '')
      })
      .catch((error) => {
        commit('SET_ERROR', error.message)
        Vue.$toast.error(
          error.message ? `${error.message}` : 'Something went wrong. Please try again.'
        )
      })
      .finally(() => commit('SET_LOADING', false))
  },
  updateLoopIds({ state, commit, rootState }, payload) {
    commit('SET_EDITABLE_LOOP_IDS', payload)
  },

  resetLoopIds({ state, commit, rootState }, payload) {
    commit('RESET_EDITABLE_LOOP_IDS', payload)
  }

  // ===========================================================================
}
