<template>
  <div class="container-fluid">
    <div class="header-campaign d-flex justify-content-between">
      <!-- Search Campaign -->
      <div :class="{ 'd-none': activeButton === 'suborg' }">
        <SfInputSearch
          v-model="dataSearch"
          :placeholder="$t('search')"
          v-debounce:500ms="searchInputChange"
          :height="'40px'"
          @onSearchInputChange="resetSearchCampaign"
        />
      </div>

      <!-- Search Campaign Children -->
      <div :class="{ 'd-none': activeButton === 'campaign' }">
        <SfInputSearch
          v-model="dataSearchCampaignChildren"
          :placeholder="$t('search')"
          v-debounce:500ms="inputCampaignChildren"
          :height="'40px'"
          @onSearchInputChange="resetSearchCampaignChildren"
        />
      </div>
      <button
        @click="isShownModal = true"
        class="sf-primary-add"
        type="button"
        :class="{ 'fixed-new-campaign': isFixed }"
      >
        {{ $t('create-new-campaign') }}
      </button>
    </div>

    <div class="row players-tabs-wraper">
      <div class="col-6">
        <div class="sf-sub-menu">
          <div class="col-md-12 top-menu">
            <ul class="nav nav-tabs nav-tabs-custom">
              <li class="acount-menu-item">
                <a
                  @click="changeTab('campaign')"
                  :class="{ 'is-active': activeButton === 'campaign' }"
                  >{{ $t('campaign') }}</a
                >
              </li>
              <li
                v-if="
                  isParentOrg &&
                  this.currentUser.organization &&
                  this.currentUser.organization.children > 0
                "
                class="acount-menu-item"
              >
                <a
                  @click="changeTab('suborg')"
                  :class="{ 'is-active': activeButton === 'suborg' }"
                  >{{ $t('sub-organizations') }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Campaign Pagination -->
      <div class="col-6">
        <div v-if="activeButton === 'campaign'" class="row mb-3">
          <div
            v-if="pagination.total > 0"
            class="col-12 d-flex justify-content-end"
            :class="{ fixed: isFixed }"
          >
            <b-pagination
              class="sf-b-pagination"
              :total-rows="limitPage.value * pagination.total"
              :per-page="limitPage.value"
              @change="changePage"
              :limit="6"
              v-model="currentPage"
            />
            <SfOptionLimitPage @changeLimitPage="changeLimitPage" :limitPage.sync="limitPage" />
          </div>
        </div>

        <!-- Sub Org pagination -->
        <div v-if="activeButton === 'suborg'" class="row">
          <div
            v-if="dataCampaignChildren.total_pages > 0"
            class="col-12 d-flex justify-content-end"
          >
            <b-pagination
              class="sf-b-pagination"
              :total-rows="10 * dataCampaignChildren.total_pages"
              :per-page="10"
              @change="changePageTableSubOrg"
              :limit="6"
              v-model="currentPageOfSubOrg"
            >
            </b-pagination>
            <SfOptionLimitPage
              @changeLimitPage="changeLimitPageSubOrg"
              :limitPage.sync="limitPageSubOrg"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="sf-table">
      <!-- Table Campaign -->
      <b-table
        :class="{ 'd-none': activeButton === 'suborg' }"
        responsive="true"
        show-empty
        striped
        hover
        :items="items"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('loading') }}...</strong>
          </div>
        </template>

        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(shared_campaign)="field"> </template>
        <template v-slot:cell(shared_campaign)="field">
          <div class="icon-shared">
            <img src="@/assets/images/share-edit-active.svg" v-if="field.item.shared_campaign" />
          </div>
        </template>

        <template v-slot:head(displayname)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`">{{
              field.item.displayname
            }}</router-link>
          </p>
        </template>

        <template v-slot:head(main_loop)="field">
          <span class="custome-arrows">{{ $t('main-loop') }}</span>
        </template>
        <template v-slot:cell(main_loop)="field">
          <router-link
            v-for="(loop, index) in field.item.main_loop"
            :key="`${field.item.id}-${loop.id}-${index}`"
            :to="`/loop_assets/${loop.id}/edit`"
          >
            {{
              index === field.item.main_loop.length - 1 ? loop.displayname : loop.displayname + ', '
            }}
          </router-link>
        </template>

        <template v-slot:head(number_of_loop)="field">
          <span class="custome-arrows">{{ $t('number-of-loop') }}</span>
        </template>
        <template v-slot:cell(number_of_loop)="field">
          <p class="number-player" @click="getCampaignLoops(field.item.campaign_code)">
            {{ field.item.number_of_loop }}
          </p>
        </template>

        <template v-slot:head(player_count)="field">
          <span class="custome-arrows">{{ $t('players') }}</span>
        </template>
        <template v-slot:cell(player_count)="field">
          <p class="number-player" @click="getNumberPlayer(field.item.campaign_code)">
            {{ field.item.player_count }}
          </p>
        </template>

        <template v-slot:head(action)="field">
          <span>{{ $t('actions') }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <div class="d-flex align-items-center">
            <button
              :title="$t('tooltip.clone')"
              @click="cloneCampain(field.item)"
              class="button-copy-table"
              v-if="!field.item.shared_campaign"
            ></button>
            <button
              :title="$t('tooltip.edit')"
              @click="goToEditCampaign(field.item)"
              class="button-edit-table"
              v-if="!field.item.shared_campaign"
            ></button>
            <button
              :title="$t('tooltip.share')"
              v-if="isParentOrg"
              @click="shownModalShare(field.item.campaign_code)"
              class="button-share-table"
            ></button>
            <button
              :title="$t('tooltip.delete')"
              @click="shownModalDelete(field.item.campaign_code)"
              class="button-delete-table"
              v-if="!field.item.shared_campaign"
            ></button>
          </div>
        </template>
      </b-table>

      <!-- Table Sub Org -->

      <div class="row mb-3" :class="{ 'd-none': !(activeButton === 'suborg') }">
        <div class="col-2">
          <div class="sf-select-group">
            <Select2
              class="sf-select2"
              v-model="subOrgSelected"
              :options="optionsSubOrg"
              @select="selectSubOrg($event)"
            />
            <span class="lb-right caret"></span>
          </div>
        </div>
      </div>

      <b-table
        :class="{ 'd-none': activeButton === 'campaign' }"
        responsive="true"
        show-empty
        striped
        hover
        :items="dataCampaignChildren.items"
        :fields="subOrgTableField"
        head-variant="none"
        table-variant="none"
        thead-class="custom-b-table"
        :busy="isLoadingDataCampaignChildren"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:empty>
          <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
        </template>

        <template v-slot:head(shared_campaign)="field"> </template>
        <template v-slot:cell(shared_campaign)="field">
          <div class="icon-shared">
            <img src="@/assets/images/share-edit-active.svg" v-if="field.item.shared_campaign" />
          </div>
        </template>

        <template v-slot:head(displayname)="field">
          <span class="custome-arrows">{{ $t('name') }}</span>
        </template>
        <template v-slot:cell(displayname)="field">
          <p>
            <router-link :to="`/campaigns/${field.item.campaign_code}/edit`">{{
              field.item.displayname
            }}</router-link>
          </p>
        </template>

        <template v-slot:head(main_loop)="field">
          <span class="custome-arrows">{{ $t('main-loop') }}</span>
        </template>
        <template v-slot:cell(main_loop)="field">
          <router-link
            v-for="(loop, index) in field.item.main_loop"
            :key="`${field.item.id}-${loop.id}`"
            :to="`/loop_assets/${loop.id}/edit`"
          >
            {{
              index === field.item.main_loop.length - 1 ? loop.displayname : loop.displayname + ', '
            }}
          </router-link>
        </template>

        <template v-slot:head(number_of_loop)="field">
          <span class="custome-arrows">{{ $t('number-of-loop') }}</span>
        </template>
        <template v-slot:cell(number_of_loop)="field">
          <p>{{ field.item.number_of_loop }}</p>
        </template>

        <template v-slot:head(player_count)="field">
          <span class="custome-arrows">{{ $t('players') }}</span>
        </template>
        <template v-slot:cell(player_count)="field">
          <p class="number-player" @click="getNumberPlayer(field.item.campaign_code)">
            {{ field.item.player_count }}
          </p>
        </template>

        <!-- <template v-slot:head(action)="field">
          <span>{{ field.label }}</span>
        </template>
        <template v-slot:cell(action)="field">
          <div class="d-flex align-items-center">
          <button title="Edit" @click="goToEditCampaign(field.item)"
            class="button-edit-table"></button>
          <button title="Delete" @click="shownModalDelete(field.item.campaign_code)"
            class="button-delete-table"></button>
          </div>
        </template> -->
      </b-table>
    </div>
    <SfModal
      :title="$t('modal.title.add-campaign')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModal"
      @onClose="cancelCreateCampaign"
    >
      <div class="sf-modal-body mb-3">
        <div class="container">
          <div class="row mt-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('name') }}</label>
                </div>
                <input
                  class="sf-input-text"
                  v-model.trim="newCampaign.campaign.displayname"
                  :placeholder="$t('placeholder.name-campaign')"
                  :class="{
                    'input-error-form':
                      showError &&
                      (!newCampaign.campaign.displayname ||
                        newCampaign.campaign.displayname.length < 2 ||
                        newCampaign.campaign.displayname.length > 60)
                  }"
                />
              </div>
              <p
                v-if="!newCampaign.campaign.displayname && showError"
                class="error-text text-right"
              >
                Please give this campaign a name
              </p>
              <p
                v-else-if="newCampaign.campaign.displayname.length < 2 && showError"
                class="error-text text-right"
              >
                {{ $t('name-minLength-2') }}
              </p>
              <p
                v-else-if="newCampaign.campaign.displayname.length > 60 && showError"
                class="error-text text-right"
              >
                {{ $t('name-maxLength-60') }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="cancelCreateCampaign">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="addNewCampaign">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
    <SfDeleteModal
      :title="$t('modal.title.confirm-delete')"
      closeOnOverlay
      :show.sync="isShownModalDelete"
    >
      <div class="content-delete">
        {{ $t('confirm-delete-campaign') }}
      </div>
      <div class="footer-button mt-3">
        <button class="sf-secondary" @click="isShownModalDelete = false">{{ $t('cancel') }}</button>
        <button class="sf-primary" @click="removeCampaign">{{ $t('ok') }}</button>
      </div>
    </SfDeleteModal>
    <SfModal
      :title="$t('players')"
      :width="768"
      closeOnOverlay
      :show.sync="isShownModalNumberPlayer"
    >
      <div class="number-player mt-3">
        <div v-for="(player, index) in numberPlayers" :key="index" class="col-4">
          <img src="@/assets/images/overview-player-blue.svg" />
          <p>
            <router-link :to="`/players/${player.id}`">{{ player.name }}</router-link>
          </p>
        </div>
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button @click="isShownModalNumberPlayer = false" class="sf-primary">
                  {{ $t('ok') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal :title="$t('loops')" :width="768" closeOnOverlay :show.sync="showCampaignModalPlayer">
      <div class="number-player mt-3">
        <div v-for="(loop, index) in campaignLoops" :key="index" class="col-4">
          <img src="@/assets/images/overview-campaign.svg" />
          <p>
            <router-link :to="`/loop_assets/${loop.id}/edit`">{{ loop.displayname }}</router-link>
          </p>
        </div>
      </div>

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button @click="showCampaignModalPlayer = false" class="sf-primary">
                  {{ $t('ok') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>

    <SfModal
      :title="$t('modal.title.share-campaign')"
      :width="786"
      :height="0"
      closeOnOverlay
      :show.sync="isShownModalShareFolder"
    >
      <div class="modal-body share-campaign">
        <div class="container">
          <div class="row my-3">
            <div class="col-9">
              <div class="sf-form-group">
                <div class="sf-label-group">
                  <label class="primary-label">{{ $t('folder-sharing') }}</label>
                </div>
                <input
                  class="sf-input-text"
                  type="text"
                  name="search"
                  id="folder_sharing"
                  :placeholder="$t('search')"
                  v-model="searchChildOrg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="admin-org-detail table-edit-folder">
          <div class="sf-table">
            <b-table
              responsive="true"
              :items="displayListOrg"
              show-empty
              striped
              hover
              :fields="subOrgFields"
              head-variant="none"
              table-variant="none"
              thead-class="custom-b-table"
              :busy="isLoading"
              :current-page="currentPageModal"
              :per-page="perPage"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t('loading') }}...</strong>
                </div>
              </template>
              <template v-slot:empty>
                <p class="d-flex justify-content-center">{{ $t('no-data-available-in-table') }}</p>
              </template>
              <template v-slot:head(shared)="field">
                <button
                  :class="isSelect ? 'sf-btn-check-active' : 'sf-btn-check'"
                  @click="selectAllSubOrg"
                ></button>
              </template>
              <template v-slot:cell(shared)="field">
                <button
                  @click="selectOrg(field.item.id)"
                  :class="field.item.selected ? 'button-term-active' : 'button-term'"
                ></button>
              </template>

              <template v-slot:head(sub_organization)="field">
                <span class="custome-arrows"></span><span>{{ $t('sub-organization') }}</span>
              </template>
              <template v-slot:cell(sub_organization)="field">
                <p>{{ field.item.name }}</p>
              </template>
            </b-table>
            <b-pagination
              class="sf-b-pagination"
              v-model="currentPageModal"
              :total-rows="displayListOrg.length"
              :per-page="perPage"
              @change="changePageSubOrg"
            >
            </b-pagination>
          </div>
        </div>
      </div>
      <!-- <div class="footer-button">
        <button @click="isShownModalShareFolder = false">
          Cancel
        </button>
        <button @click="onClickSave">
          Save
        </button>
      </div> -->

      <div class="sf-modal-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="sf-modal-action">
                <button class="sf-secondary" type="button" @click="isShownModalShareFolder = false">
                  {{ $t('cancel') }}
                </button>
                <button type="button" class="sf-primary" @click="onClickSave">
                  {{ $t('save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SfModal>
  </div>
</template>
<script>
import SfModal from '@/components/SfModal.vue'
import SfDeleteModal from '@/components/SfDeleteModal.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CampaignService from '../../services/campaign.service'
import { EventBus } from '@/event-bus.js'
import OrganizationService from '@/services/organization.service'
import SfInputSearch from '@/components/SfInputSearch.vue'
import SfOptionLimitPage from '@/components/SfOptionLimitPage.vue'
import Select2 from 'v-select2-component'

export default {
  name: 'campaign',
  components: {
    SfModal,
    SfDeleteModal,
    SfInputSearch,
    SfOptionLimitPage,
    Select2
  },
  data() {
    return {
      content: 'a',
      fields: [
        {
          key: 'displayname',
          sortable: true,
          colType: 'displayname'
        },
        {
          key: 'main_loop',
          sortable: true,
          colType: 'main_loop',
          tdClass: 'main_loop'
        },
        {
          key: 'number_of_loop',
          sortable: true,
          colType: 'number'
        },
        {
          key: 'player_count',
          sortable: true,
          colType: 'players'
        },
        {
          key: 'Action',
          colType: 'button'
        }
      ],
      subOrgFields: [
        {
          key: 'shared',
          colType: 'shared',
          label: 'Shared',
          class: 'check-share',
          sortable: false
        },
        {
          key: 'sub_organization',
          sortable: true,
          colType: 'sub_organization'
        }
      ],
      subOrgTableField: [
        {
          key: 'displayname',
          sortable: true,
          colType: 'displayname'
        },
        {
          key: 'main_loop',
          sortable: true,
          colType: 'main_loop',
          tdClass: 'main_loop'
        },
        {
          key: 'number_of_loop',
          sortable: true,
          colType: 'number'
        },
        {
          key: 'player_count',
          sortable: true,
          colType: 'players'
        }
      ],
      isShownModal: false,
      isShownModalDelete: false,
      isShownModalNumberPlayer: false,
      showCampaignModalPlayer: false,
      isShownModalShareFolder: false,
      newCampaign: {
        campaign: {
          displayname: ''
        }
      },
      currentPage: 1,
      limitPage: {
        name: '25 / page',
        value: '25'
      },
      limitPageSubOrg: {
        name: '25 / page',
        value: '25'
      },
      currentCampaignID: '',
      currentCampaign: '',
      dataSearch: '',
      showError: false,
      numberPlayers: [],
      campaignLoops: [],
      listOrg: [],
      displayListOrg: [],
      currentPageModal: 1,
      perPage: 10,
      searchChildOrg: '',
      isLoading: false,
      shared_organization_ids: [],
      isSelect: false,
      isParentOrg: this.$cookies.get('user').is_parent_organization,
      user: {},
      activeButton: 'campaign',
      subOrgSelected: '',
      optionsSubOrg: [],
      currentPageOfSubOrg: 1,
      dataSearchCampaignChildren: '',
      isFixed: false,
      lastScrollTop: 0,
      lastScrollLeft: 0
    }
  },
  watch: {
    editCampaignIds(newVal) {},
    listOrg(value) {
      this.getCheckedOrg(value)
      this.displayListOrg = value.filter((org) => {
        return this.searchChildOrg
          .toLowerCase()
          .split(' ')
          .every((v) => org.name.toLowerCase().includes(v))
      })
    },
    shared_organization_ids(value) {
      this.isSelect = value.length === this.listOrg.length
    },
    searchChildOrg(value) {
      if (value) {
        this.displayListOrg = this.listOrg.filter((org) => {
          return value
            .toLowerCase()
            .split(' ')
            .every((v) => org.name.toLowerCase().includes(v))
        })
      } else {
        this.displayListOrg = this.listOrg
      }
    },
    currentUser: {
      immediate: true,
      handler() {
        const organization = this.currentUser.organization
        if (!organization) return
        this.$cable.subscribe({
          channel: 'CampaignChannel',
          organization: organization.id
        })
        if (!organization.is_parent_organization) {
          this.fields.unshift({
            key: 'shared_campaign',
            sortable: true,
            colType: 'shared_campaign',
            label: '',
            thClass: 'icon-table'
          })
        }
      }
    }
  },
  methods: {
    ...mapActions('campaign', {
      getAllCampaignChildren: 'getAllCampaignChildren',
      cloneCampaignByID: 'cloneCampaignByID'
    }),
    ...mapMutations('campaign', {
      createFromSocket: 'CREATE_FROM_SOCKET',
      deleteFromSocket: 'DELETE_FROM_SOCKET',
      updateFromSocket: 'UPDATE_FROM_SOCKET'
    }),
    handleScroll() {
      const scrollTop = document.getElementById('pages').scrollTop
      const scrollLeft = document.getElementById('pages').scrollLeft

      if (scrollTop !== this.lastScrollTop) {
        // vertical scroll
        this.isFixed = false
      } else if (scrollLeft !== this.lastScrollLeft) {
        // horizontal scroll
        this.isFixed = true
      }

      this.lastScrollTop = scrollTop
      this.lastScrollLeft = scrollLeft
    },
    addNewCampaign() {
      if (
        this.newCampaign.campaign.displayname &&
        this.newCampaign.campaign.displayname.length > 1 &&
        this.newCampaign.campaign.displayname.length <= 60
      ) {
        this.$store.dispatch('campaign/addNewCampaign', this.newCampaign)
        this.isShownModal = false
      } else {
        this.showError = true
      }
    },
    cancelCreateCampaign() {
      this.isShownModal = false
      this.showError = false
      this.newCampaign.campaign.displayname = ''
    },
    fetchData(activePage, limit, search) {
      this.$store.dispatch('campaign/getAllCampaign', { activePage, limit, search })
    },
    changePage(page) {
      this.currentPage = page
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    shownModalDelete(campaignID) {
      this.isShownModalDelete = true
      this.currentCampaignID = campaignID
    },
    shownModalShare(campaignID) {
      this.isShownModalShareFolder = true
      this.currentCampaignID = campaignID
      this.searchChildOrg = ''
      this.currentPageModal = 1
      CampaignService.getCampaignByID(campaignID)
        .then((res) => {
          this.currentCampaign = res.data
          this.getAllChildOrganization()
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
    },
    removeCampaign() {
      return CampaignService.remove(this.currentCampaignID)
        .then((res) => {
          this.isShownModalDelete = false
          if (this.items.length === 1 && this.currentPage > 1) {
            this.currentPage = this.currentPage - 1
          }
          this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
          this.$toast.success(`${res.message || 'Campaign successfully deleted'}`)
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          this.isShownModalDelete = false
        })
    },
    getNumberPlayer(id) {
      return CampaignService.getNumberPlayersByCampaignID(id)
        .then((res) => {
          if (res.data.length) {
            this.numberPlayers = res.data
            this.isShownModalNumberPlayer = true
          } else {
            this.$toast.error('No players are using this campaign')
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          this.isShownModalNumberPlayer = false
        })
    },
    getCampaignLoops(id) {
      return CampaignService.getLoopsByCampaignID(id)
        .then((res) => {
          if (res.data.length) {
            this.campaignLoops = res.data
            this.showCampaignModalPlayer = true
          } else {
            this.$toast.error('No loop are using this campaign')
          }
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
          this.showCampaignModalPlayer = false
        })
    },
    getAllChildOrganization() {
      const self = this
      this.isLoading = true
      OrganizationService.getAllChildOrganization('campaign')
        .then((res) => {
          self.listOrg = res.data
          self.displayListOrg = res.data
          const sharedOrganizationIds = self.currentCampaign.shared_organizations
          self.listOrg.map((org) => {
            org.selected = sharedOrganizationIds.length && sharedOrganizationIds.includes(org.id)
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    selectOrg(id) {
      const listOrg = this.listOrg
      const orgSelected = listOrg.findIndex((org) => org.id === id)
      listOrg[orgSelected].selected = !listOrg[orgSelected].selected
      this.listOrg = [...listOrg]
    },
    selectAllSubOrg() {
      this.isSelect = !this.isSelect
      const listOrg = this.listOrg
      listOrg.map((org) => {
        org.selected = this.isSelect
      })
      this.listOrg = [...listOrg]
    },
    getCheckedOrg(listOrg) {
      this.shared_organization_ids = listOrg
        .filter(function (org) {
          return org.selected
        })
        .map((x) => x.id)
    },
    onClickSave() {
      this.currentCampaign.shared_organization_ids = this.shared_organization_ids
      CampaignService.editCampaignByID({ campaign: this.currentCampaign }, this.currentCampaignID)
        .then((res) => {
          const count = res.data.shared_organizations.length
          let message = 'Campaign updated'
          if (count > 0) {
            message =
              count > 1
                ? 'This campaign is now shared with ' + count + ' organizations'
                : 'This campaign is now shared with ' + count + ' organization'
          }
          this.$toast.open({
            message: message,
            type: 'success'
          })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong. Please try again.')
        })
        .finally(() => {
          this.isShownModalShareFolder = false
        })
    },
    changePageSubOrg(page) {
      this.currentPageModal = page
    },
    goToEditCampaign(campaign) {
      this.$router.push(`/campaigns/${campaign.campaign_code}/edit`)
    },
    searchInputChange(value) {
      this.dataSearch = value
      this.currentPage = 1
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    inputCampaignChildren(value) {
      this.dataSearchCampaignChildren = value
      this.currentPageOfSubOrg = 1
      this.getAllCampaignChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrg,
        limit: this.limitPageSubOrg.value,
        search: this.dataSearchCampaignChildren
      })
    },
    changeTab(value) {
      this.activeButton = value
      if (value === 'suborg') {
        this.getAllCampaignChildren({
          id: this.subOrgSelected,
          page: this.currentPageOfSubOrg,
          limit: this.limitPageSubOrg.value,
          search: this.dataSearchCampaignChildren
        })
      } else {
        this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
      }
    },
    selectSubOrg(value) {
      const id = typeof value === 'object' ? value.id : parseInt(value)
      this.getAllCampaignChildren({
        id: id,
        page: this.currentPageOfSubOrg,
        limit: this.limitPageSubOrg.value,
        search: this.dataSearchCampaignChildren
      })
    },
    getListChildOrg() {
      OrganizationService.getAllChildOrganization()
        .then((res) => {
          const listChildOrg = res.data
          listChildOrg &&
            listChildOrg.length &&
            listChildOrg.map((org) => {
              this.optionsSubOrg.push({ id: org.id, text: org.name })
              this.subOrgSelected = listChildOrg[0].id
            })
        })
        .catch((err) => {
          this.$toast.error(err.message ? err.message : 'Something went wrong.')
        })
    },
    changePageTableSubOrg(page) {
      this.currentPageOfSubOrg = page
      this.getAllCampaignChildren({
        id: this.subOrgSelected,
        page: page,
        limit: this.limitPageSubOrg.value,
        search: this.dataSearchCampaignChildren
      })
    },
    resetSearchCampaign() {
      this.dataSearch = ''
      this.fetchData(this.currentPage, this.limitPage.value)
    },
    resetSearchCampaignChildren() {
      this.dataSearchCampaignChildren = ''
      this.getAllCampaignChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrg,
        limit: this.limitPageSubOrg.value
      })
    },
    changeLimitPage(data) {
      this.currentPage = 1
      this.limitPage = data
      this.fetchData(this.currentPage, this.limitPage.value, this.dataSearch)
    },
    changeLimitPageSubOrg(data) {
      this.currentPageOfSubOrg = 1
      this.limitPageSubOrg = data
      this.getAllCampaignChildren({
        id: this.subOrgSelected,
        page: this.currentPageOfSubOrg,
        limit: this.limitPageSubOrg.value,
        search: this.dataSearchCampaignChildren
      })
    },
    cloneCampain(data) {
      this.cloneCampaignByID(data)
    }
  },
  computed: {
    ...mapState('campaign', {
      items: 'items',
      pagination: 'pagination',
      error: 'error',
      loading: 'loading',
      dataCampaignChildren: 'dataCampaignChildren',
      isLoadingDataCampaignChildren: 'isLoadingDataCampaignChildren'
    }),
    ...mapGetters('campaign', ['isEmpty']),
    ...mapGetters('user', {
      currentUser: 'getCurrentUser'
    }),
    pages() {
      const pageList = []
      const totalPage = this.pagination.total
      if (totalPage) {
        let page = 1
        for (page; page <= totalPage; page++) {
          pageList.push(page)
        }
      }
      return pageList
    }
  },
  channels: {
    CampaignChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (data.action === 'create') {
          this.createFromSocket({ data: data.data })
        } else if (data.action === 'delete') {
          this.deleteFromSocket({ data: data.data })
        } else if (data.action === 'update') {
          this.updateFromSocket({ data: data.data })
        }
      },
      disconnected() {}
    }
  },
  mounted() {
    document.getElementById('pages').addEventListener('scroll', this.handleScroll)
    this.fetchData(this.currentPage, this.limitPage.value)
    EventBus.$on('turn_off_modal', (value) => {
      this.isShownModal = value
    })
    this.getListChildOrg()
  },
  beforeDestroy() {
    this.$cable.unsubscribe('CampaignChannel')
    document.getElementById('pages').removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped lang="scss">
@import 'campaignPage.scss';
.fixed {
  position: fixed;
  right: 9px;
}
.fixed-new-campaign {
  position: fixed;
  right: 24px;
}
a:hover {
  color: #007bff;
  text-decoration: none;
}
.number-player {
  cursor: pointer;
  color: #2c7fe1;
}

.icon-shared {
  display: flex;
  justify-content: center;
  padding: inherit;
  img {
    width: 25px;
  }
}

.players-tabs-wraper {
  height: 56px;
}

.share-campaign {
  padding: 1rem 2rem;
  .form-search {
    margin: auto;
    label {
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4a4a4a;
    }
    .input-search {
      background-image: url(~@/assets/images/magnifying-glass.svg);
      background-repeat: no-repeat;
      background-position: 15px center;
      padding: 12px 20px 12px 50px;
    }
  }
  .checked-all {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 25px;
    padding-top: 25px;
    line-height: 30px;
    font-size: 14px;
    font-weight: normal;
    color: #4a4a4a;
    p {
      padding-left: 10px;
      font: 14px 'Open Sans', sans-serif;
    }
  }
  .custom-wrapper {
    padding: unset;
    margin-top: 20px;
  }
  .button-term {
    width: 25px;
    height: 25px;
    background-size: cover;
    background-color: transparent;
    border: 1px solid #a7b1c7;
  }
  .button-term-active {
    background-image: url(~@/assets/images/checked-all.svg);
    width: 25px;
    height: 25px;
    background-size: cover;
    background-color: transparent;
    border: 1px solid #a7b1c7;
  }
}
</style>
